import React, { useState, useEffect } from 'react';
import "./style.scss"
import PieChart from './pieChart'
import { useSelector } from 'react-redux';
import  BarChartComp  from './BarChart'
import {getSkuSummary, getMarginUnitsSummary, getPieChartData, getBarChartData} from '../../urlConstants'
import GridTable from '../../components/GridTable';
import restUtils from '../../utils/restUtils';
import utilities from '../../utils/utilities';
import { useImmer } from 'use-immer';
import { barChatText, COLORS } from '../common/chartUtilities'
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';
import  usePrevious from '../common/usePrevious'
export const Home = (props) => {
  const { filterType, skuType, selectedCategory, finelineHierarchy,seletedSku} = useSelector(state => state.DropDownReducer)
  const { storeId, programCode } = useSelector(state => state.SessionReducer)
  let  pieDataColors = {}
  let filterCategory =  _.uniqBy(finelineHierarchy, obj=>obj.deptName)
  filterCategory.forEach((ele,index)=>{
    pieDataColors = {
      ...pieDataColors,
      [ele.deptCode] : COLORS[index]
    }        
  });
  const [pieChartData, setPieChartData] = useState([])
  const [ year, setYear] = useState(1)
  const [breakDown, setBreakDown] = useState({})
  const [gridSelection, setGridSelection] = useImmer({
    isSingleSku: false,
    skuValue:null,
    skuImage: null,
    deptCode: null
  })
  const [ pieSelection, setPieSelection] =  useImmer({
      isSinglePie : false,
      deptCode:null,
  })
  const [ barChartData, setBarChartData] = useImmer({
    topProduct:null,
    bottomProduct:null,
    isLoading: true
  })
  const [ gridData, setGridData] = useImmer({
    isLoading:true,
    rowData :null,
    totalData:null,
    itemCount:'' ,
  })
  const [ pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 10
 })
 const [ sortCol, setSortCol] = useImmer({
  colIndex:8,
  orderAsc:false,
  perPage: 20,
})

const prevSortCol = usePrevious(sortCol)
   useEffect(()=>{
    const value = selectedCategory.map(ele => ele.uniqCode)
  
    let  pieChartPayload = {
      "memberNbr": storeId,
      "ytdYear": year,
      "filterType": gridSelection.isSingleSku ? 'Dept' : value.length > 0 ? 'Dept': "None",
      "filterValues": gridSelection.isSingleSku ? [gridSelection.deptCode] : value,
      "skuType": parseInt(skuType)
     }
    if(filterType.name==='Sku') {
      pieChartPayload = {
        "memberNbr": storeId,
        "ytdYear": year,
        "filterType": filterType.value.length === 0 ? 'Dept': 'Sku',
        "filterValues":filterType.value.length === 0 ? value : filterType.value,
        "skuType": parseInt(skuType)
       }

    }
    restUtils.postData(getPieChartData, pieChartPayload).then(response=>{
      let result = response.data.margins
      result.sort((a,b)=> a.marginPercent - b.marginPercent)
      setPieChartData(result)
     }).catch(err=>{
      console.log(err)
    })   
            

   },[storeId,selectedCategory,year,gridSelection,filterType])
   const fetchMarginUnitsSummary = (payload ={}) => {
    
     restUtils.postData(`${getMarginUnitsSummary}`, payload).then(response=>{
      setBreakDown(response.data)
   }).catch(err=>{
     console.log(err)
   })  
   }
   useEffect(()=>{
    const payload = {
      "memberNbr": storeId,
      "filterType": filterType.name,
      "filterValues":filterType.value,
      "skuType": parseInt(skuType)
     }
     fetchMarginUnitsSummary(payload)
   },[storeId,filterType,skuType])
   useEffect(()=>{
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuImage = null
    })
   },[skuType])
   useEffect(()=>{
    const payload = {
      "memberNbr": storeId,	
      "filterType": filterType.name,
      "filterValues": filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": sortCol.perPage,
      "pageNbr": 1,
      "sortColumn": sortCol.colIndex,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
    fetchSkuSummary(payload)
   },[storeId,filterType,skuType])
   const fetchSkuSummary = (payload = {}) =>{
    let gridPayload = payload;
    setGridData(draft=>{
      draft.isLoading = true
    })
   restUtils.postData(getSkuSummary, gridPayload).then(response=>{
    const data = response.data
    setGridData(draft=>{
      draft.isLoading = false
    })
    setGridData(draft=>{
      draft.rowData = data.skuFinancials;
      draft.totalData = data.skuFinancialsTotal;
      draft.itemCount = data.skuFinancialsTotal.totalCount;
    })
    setPagination(draft=>{
      draft.totalPages = data.skuFinancialsTotal.pageCount
    })
   }).catch(err=>{
    console.log(err)
    setGridData(draft=>{
      draft.isLoading = false
    })
   })
  }
  
useEffect(()=>{
    if( filterType.name !== 'Sku') {
      const payload = {
        "filterType": filterType.name,
        "filterValues":filterType.value,
        "skuType": parseInt(skuType),
        "memberNbr": storeId,
        "ytdYear": year,
        "N": 10
    }
    fetchBarChart(payload)
  }
},[year,storeId,filterType,skuType])
  const fetchBarChart = (payload = {}) =>{
    restUtils.postData(getBarChartData, payload).then(res=>{
      setBarChartData(draft=>{
        draft.isLoading = true
      })
      const data = res.data
      let topProduct = barChatText(data.topN)
      topProduct = topProduct.map(data=>{
        return {
          ...data ,
          xAxisText: utilities.formatNumber(data.ytdMargin)
        }
      })
      let bottomProduct = barChatText(data.bottomN)
      setBarChartData(draft=>{
        draft.topProduct = topProduct;
        draft.bottomProduct = bottomProduct;
        draft.isLoading = false
      })
      }).catch(err=>{
        console.log(err)
        setBarChartData(draft=>{
          draft.isLoading = false
        })
      })   
  }
 
  const handleYear =(e) =>{
    const isChecked = e.target.checked
    if(isChecked) {
      setYear(0)
    }else {
      setYear(1)
    }
  }
  const onPageChange = e => {
    const text = e.currentTarget.text
    let page = ''
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuValue=null;
      draft.skuImage= null;
      draft.deptCode= null;
    })
    if(!isNaN(parseInt(e.currentTarget.text))) {
       setPagination(draft=>{
          draft.currentPage = parseInt(text);
       })
       page = parseInt(text)
    }
    if (e.currentTarget.text === 'Next') {
       setPagination(draft=>{
          draft.currentPage = draft.currentPage < draft.totalPages
          ? parseInt(draft.currentPage) + 1
          : draft.currentPage;
       })
       page = pagination.currentPage < pagination.totalPages
       ? parseInt(pagination.currentPage) + 1
       : pagination.currentPage
    }
    if (e.currentTarget.text === 'Prev') {
       setPagination(draft=>{
          draft.currentPage = draft.currentPage === 1 ? 1 : draft.currentPage - 1;
       })
       page =  pagination.currentPage === 1 ? 1 : pagination.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
       setPagination(draft=>{
          draft.currentPage = draft.totalPages;
       })
       page = pagination.totalPages;
    }
    if (e.currentTarget.text === '«') {
      setPagination(draft=>{
       draft.currentPage = 1
    })
      page =  1;
 
    }
    const payload = {
      "memberNbr": storeId,	
      "filterType": pieSelection.isSinglePie ? 'Dept': filterType.name,
      "filterValues": pieSelection.isSinglePie? pieSelection.deptCode : filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": sortCol.perPage,
      "pageNbr": page,
      "sortColumn": sortCol.colIndex,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
    fetchSkuSummary(payload)
  };
  const handleCount = (e)=>{
    const { value}= e.target
    setSortCol(draft=>{
       draft.perPage = value
    })
    setPagination(draft=>{
      draft.currentPage = 1
    })
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuValue=null;
      draft.skuImage= null;
      draft.deptCode= null;
    })
    const payload = {
      "memberNbr": storeId,	
      "filterType": pieSelection.isSinglePie ? 'Dept': filterType.name,
      "filterValues": pieSelection.isSinglePie? pieSelection.deptCode : filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": value,
      "pageNbr":1,
      "sortColumn": sortCol.colIndex,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
    fetchSkuSummary(payload)
    const marginUnitPayload = {
      "memberNbr": storeId,
      "filterType": pieSelection.isSinglePie ? 'Dept': filterType.name,
      "filterValues": pieSelection.isSinglePie? pieSelection.deptCode : filterType.value,
      "skuType": parseInt(skuType)
    }
    fetchMarginUnitsSummary(marginUnitPayload)
 }
 const resetSingleSelection = ()=>{
  setGridSelection(draft=>{
    draft.isSingleSku= false;
    draft.skuValue=null;
    draft.skuImage= null;
    draft.deptCode= null;
  })
  setPieSelection(draft=>{
    draft.isSinglePie= false;
    draft.deptCode= null;
  })
 }
 const handleSort = (cell, index)=>{

  setSortCol(draft=>{
   draft.colIndex = index;
   draft.orderAsc = index === 8 ? !draft[cell.key] : draft[cell.key]
   draft[cell.key] = !draft[cell.key]
  })
  resetSingleSelection()
 }
 useEffect(()=>{
  if(prevSortCol !== undefined && prevSortCol !== sortCol) {
    const payload = {
      "memberNbr": storeId,	
      "filterType": pieSelection.isSinglePie ? 'Dept': filterType.name,
      "filterValues": pieSelection.isSinglePie? pieSelection.deptCode : filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": sortCol.perPage,
      "pageNbr": pagination.currentPage,
      "sortColumn":sortCol.colIndex,
      "sortOrder":  sortCol.orderAsc ? 'asc' : 'desc'
   }
    fetchSkuSummary(payload)
  }
 },[sortCol])
 const handlePiChartSelection = (value) =>{
  setPieSelection(draft=>{
    draft.isSinglePie = !_.isEmpty(value) ? true : false;
    draft.deptCode = !_.isEmpty(value) ? [value] : null
  })
  setPagination(draft=>{
    draft.currentPage = 1;
  })
  const gridPayload = {
    "memberNbr": storeId,	
    "filterType": !_.isEmpty(value) ? 'Dept': filterType.name,
    "filterValues": !_.isEmpty(value)?  [value] : filterType.value,
    "skuType": parseInt(skuType),
    "rowsPerPage": sortCol.perPage,
    "pageNbr": 1,
    "sortColumn": sortCol.colIndex,
    "sortOrder": sortCol.orderAsc ? 'asc' : 'desc'
 }
 fetchSkuSummary(gridPayload)
 const marginUnitPayload = {
  "memberNbr": storeId,
  "filterType":!_.isEmpty(value) ? 'Dept':filterType.name,
  "filterValues":!_.isEmpty(value) ? !_.isEmpty(value) ? [value] : null: filterType.value,
  "skuType": parseInt(skuType)
}
fetchMarginUnitsSummary(marginUnitPayload)
  if( filterType.name !== 'Sku') {
    const barChartPayload = {
      "filterType": !_.isEmpty(value) ? 'Dept':filterType.name,
      "filterValues":  !_.isEmpty(value) ? !_.isEmpty(value) ? [value] : null: filterType.value,
      "skuType": parseInt(skuType),
      "memberNbr": storeId,
      "ytdYear": year,
      "N": 10
  }
  fetchBarChart(barChartPayload)
  }
}
 const handleRowSelection = (cell, header = false) => {
  if(!_.isEmpty(cell)) {
    setGridSelection(draft=>{
      draft.skuValue = cell.skuNbr;
      draft.deptCode = cell.deptCode;
      draft.isSingleSku = true;
      draft.skuImage= cell.isTvSku ? cell.skuNbr: null;
    })
  }else {
    if(pieSelection.isSinglePie) {
      setGridSelection(draft=>{
        draft.isSingleSku= false;
        draft.skuValue=null;
        draft.skuImage= null;
        draft.deptCode= null;
      })
    }else {
      resetSingleSelection()
    }    
  }
  const marginUnitPayload = {
    "memberNbr": storeId,
    "filterType": !_.isEmpty(cell) ? 'Sku':filterType.name,
    "filterValues":!_.isEmpty(cell) ? [cell.skuNbr] : filterType.value,
    "skuType": parseInt(skuType)
  }
  fetchMarginUnitsSummary(marginUnitPayload)

}
useEffect(()=>{
  setPagination(draft=>{
    draft.currentPage = 1
  })
  resetSingleSelection()
},[filterType, skuType])
  const { 
    marginLTM , marginLTMChangePct, marginLTMLastYear,marginYTD,marginYTDChangePct,
    marginYTDLastYear, unitsLTM, unitsLTMChangePct, unitsLTMLastYear, unitsYTD, unitsYTDChangePct,
    unitsYTDLastYear
    } =  breakDown
  return(
    <>
    {programCode !== 'ROS' && (
      <>
    <section className='chartWrapper'>
      <div className='pieChartWrapper'>
      <PieChart data = {pieChartData} handleSelection={handlePiChartSelection} isSinglePieCode = {pieSelection.deptCode} colors={pieDataColors} resetSelection={pieSelection.isSinglePie}  gridSelection={gridSelection.isSingleSku}/>
      </div>
      <div className='barChartWrapper'>
        <div className='toggleBtn'>
        <span>Current Year</span>
        <span><input type="checkbox" id="switch" onChange={handleYear}/><label htmlFor="switch">Toggle</label></span>
        <span>Last Year</span>
        </div>
      <div className='barChartDivs'>
        {!gridSelection.isSingleSku && seletedSku.length === 0 && (
        <>
        {barChartData.isLoading && <Loader active /> }
        {!barChartData.isLoading && (

          <>
            <div>
            <h4>Top 10 by Margin Dollars</h4>
            {barChartData.topProduct.length > 0 ? (
              <BarChartComp name={'topTenChart'} data={barChartData.topProduct}/>
            ):<p className='errorMsg'>Insufficient Data</p>}           
            </div>
            <div>
            <h4>Bottom 10 by Margin Dollars</h4>
            {barChartData.bottomProduct.length > 0 ? (
                <BarChartComp name={'bottomTenChart'} data={barChartData.bottomProduct}/>
              ):<p className='errorMsg'>Insufficient Data</p>}                
            </div>
          </>                
        )}
        </>
        )}
        
      </div>
      </div>
    </section>
    <section className='marginBreakDownWrapper'>
      <div>
        <div className='topRowBox'>
        <Box value={marginYTD} label="YTD Margin $" percentage={marginYTDChangePct} symbol={true}/>
        <Box value={marginYTDLastYear} label="YTD LY Margin $" percentage={null} symbol={true}/>
        <Box value={marginLTM} label="LTM Margin $" percentage={marginLTMChangePct} symbol={true}/>
        <Box value={marginLTMLastYear} label="LY LTM Margin $" percentage={null} symbol={true}/>
         </div>
         <div className='bottomRowBox'>
         <Box value={unitsYTD} label="YTD Units" percentage={unitsYTDChangePct} symbol={false}/>
         <Box value={unitsYTDLastYear} label="YTD LY Units" percentage={null} symbol={false}/>
         <Box value={unitsLTM} label="LTM Units" percentage={unitsLTMChangePct} symbol={false}/>
         <Box value={unitsLTMLastYear} label="LY LTM Units" percentage={null} symbol={false}/>
         </div>
      </div>

    </section>
    <section className='gridTableSection'>
     {gridData.isLoading && <Loader active/>}
    {gridData.rowData && (
      gridData.rowData.length > 0 ? (
        <GridTable 
        id={'TabOne'}
        data = {gridData}
        pagination = {pagination}
        sortCol = {sortCol}
        onPageChange={onPageChange}
        handleCount={handleCount}
        handleSort ={handleSort}
        handleSelection = {handleRowSelection}
        image={gridSelection.skuImage}
        isSkuSelection = {gridSelection.isSingleSku}
        defaultSort= {8}
         />
        
      ): <p className='noDataMsg'>Insufficient data</p>)}

    </section>
    </>
    )}
    </>
  )
}
const Box = (props) =>{
  const { value, label ,percentage , symbol} = props
  return(
    <div className='breakDownBox'>
      <span className='boxAmount'>{symbol && '$' }{utilities.formatNumber(value)}</span>
      <span className='boxLabel'>{label}</span>
      {percentage ? <span className= {percentage > 0 ? 'boxPercentage greenText' :'boxPercentage'} >{percentage}%</span> :''}      
    </div>
  )
}
export default Home;