import React, { useEffect, useState } from 'react';
import "./style.scss"
import { useSelector } from 'react-redux';
import {getSkuSummary_WhatIfCalc, getSkuPricingDetail,updateNewPrice} from '../../urlConstants'
import GridTable from '../../components/GridTable';
import restUtils from '../../utils/restUtils';
import { useImmer } from 'use-immer';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';
import utilities from '../../utils/utilities';
import {marginAnalysis} from './priceCalc'
import history from '../../history';
import { Prompt } from 'react-router-dom';
import { header} from '../../components/GridTable/gridHeader'
export const WhatIfCalculator = (props) => {
  const { filterType, skuType, selectedCategory} = useSelector(state => state.DropDownReducer)
  const { storeId, programCode, storeDetails } = useSelector(state => state.SessionReducer)
  const [gridSelection, setGridSelection] = useImmer({
    isSingleSku: false,
    skuValue:null,
    skuImage: null,
    upc: null,
    isNonTvSku: false,
  })

  const [ gridData, setGridData] = useImmer({
    isLoading:true,
    rowData :null,
    totalData:null,
    itemCount:'' ,
  })
  const [ pagination, setPagination] = useImmer({
    currentPage: 1,
    totalPages: 10
 })
 const [ sortCol, setSortCol] = useImmer({
  colIndex:17,
  orderAsc:false,
  perPage: 20,
})
const [ initLoad, setInitLoad] = useState(false)
const [ enablePrice , setEnablePrice ] = useState(false) 
const [ priceDetails, setPriceDetails] = useImmer({
  minPrice:1,
  maxPrice:100,
  skuNbr: null,
  newPrice: null,
  regularPrice :null,
  marginAnalysisAttributes:null,
  hasLineItems: null,
})

const [ newPrice, setNewPrice] = useState('')
const [ labelVal, setLabelVal] = useState('')
const [marginAnalysisVal, setMarginAnalysisVal] = useState([])
const [ defaultView, setDefaultView] = useState(true)
const [ modifyPrice, setModifyPrice] = useImmer({})
useEffect(()=>{
  if(filterType.name !== 'None') {
    setInitLoad(true)
  }
},[filterType])
  useEffect(()=>{ 
    if(initLoad) {
     const gridPayload = {
      "memberNbr": storeId,	
      "filterType": filterType.name === 'Class' && !_.isEmpty(selectedCategory)  ? 'Dept' : filterType.name,
      "filterValues": filterType.name === 'Class' && !_.isEmpty(selectedCategory)  ? _.map(selectedCategory, 'value'): filterType.value,
      "skuType": parseInt(skuType),
      "rowsPerPage": sortCol.perPage,
      "pageNbr": pagination.currentPage,
      "sortColumn": sortCol.colIndex,
      "sortOrder": sortCol.orderAsc ?  'asc': 'desc'
   }
   setGridData(draft=>{
    draft.isLoading = true
  })
  utilities.setLoader(true)
   restUtils.postData(getSkuSummary_WhatIfCalc, gridPayload).then(response=>{

    const data = response.data
    setGridData(draft=>{
      draft.isLoading = false
    })
    setGridData(draft=>{
      draft.rowData = data.skuPrices;
      draft.totalData = data.skuTotal;
      draft.itemCount = data.skuTotal.totalCount;
    })
    setPagination(draft=>{
      draft.totalPages = Math.ceil(data.skuTotal.totalCount/sortCol.perPage)
    })
    if(data.skuPrices.length === 1) {
      setGridSelection(draft=>{
        draft.skuValue = data.skuPrices[0].skuNbr;
        draft.upc =  data.skuPrices[0].upc;
        draft.isSingleSku = true;
        draft.skuImage=  data.skuPrices[0].isTvSku ?  data.skuPrices[0].skuNbr: null;
        draft.isNonTvSku = !data.skuPrices[0].isTvSku
      })
      if( data.skuPrices[0].isTvSku) {
        setEnablePrice(true)
      }
    }
    else {
      setGridSelection(draft=>{
        draft.isSingleSku= false;
        draft.skuValue=null;
        draft.skuImage= null;
        draft.upc= null;
        draft.isNonTvSku = false
      })
    }
    utilities.setLoader(false)
   }).catch(err=>{
    console.log(err)
    setGridData(draft=>{
      draft.isLoading = false
    })
    utilities.setLoader(false)
   })
    }
   },[storeId,initLoad,filterType,skuType,sortCol,pagination.currentPage])


  
  const onPageChange = e => {
    const text = e.currentTarget.text
    resetSingleSelection()
    if(!isNaN(parseInt(e.currentTarget.text))) {
       setPagination(draft=>{
          draft.currentPage = parseInt(text);
       })
    }
    if (e.currentTarget.text === 'Next') {
       setPagination(draft=>{
          draft.currentPage = draft.currentPage < draft.totalPages
          ? parseInt(draft.currentPage) + 1
          : draft.currentPage;
       })
    }
    if (e.currentTarget.text === 'Prev') {
       setPagination(draft=>{
          draft.currentPage = draft.currentPage === 1 ? 1 : draft.currentPage - 1;
       })
    }
    if (e.currentTarget.text === '»') {
       setPagination(draft=>{
          draft.currentPage = draft.totalPages;
       })
    }
    if (e.currentTarget.text === '«') {
      setPagination(draft=>{
       draft.currentPage = 1
    })
 
    }
  };
  const handleCount = (e)=>{
    const { value}= e.target
    setSortCol(draft=>{
       draft.perPage = value
    })
    setPagination(draft=>{
      draft.currentPage = 1
    })
    resetSingleSelection()
 }
 const resetSingleSelection = ()=>{
  setGridSelection(draft=>{
    draft.isSingleSku= false;
    draft.skuValue=null;
    draft.skuImage= null;
    draft.upc= null;
    draft.isNonTvSku = false
  })
  setEnablePrice(false)
 }
 const handleSort = (cell, index)=>{

  setSortCol(draft=>{
   draft.colIndex = index;
   draft.orderAsc = index === 17 ? !draft[cell.key] : draft[cell.key]
   draft[cell.key] = !draft[cell.key]
  })
  resetSingleSelection()
 }
 const handleRowSelection = (cell, index) => {
  if(!_.isEmpty(cell)) {
    setGridSelection(draft=>{
      draft.skuValue = cell.skuNbr;
      draft.upc = cell.upc;
      draft.isSingleSku = true;
      draft.skuImage= cell.isTvSku ? cell.skuNbr: null;
      draft.isNonTvSku = !cell.isTvSku
    })
    if(cell.isTvSku) {
      setEnablePrice(true)
    }

  }else {
    setGridSelection(draft=>{
      draft.isSingleSku= false;
      draft.skuValue=null;
      draft.skuImage= null;
      draft.upc= null;
      draft.isNonTvSku = false
    })
    if(gridData.rowData.length === 1) {
      setGridSelection(draft=>{
        draft.isNonTvSku = !gridData.rowData[0].isTvSku
      })
    }
    setEnablePrice(false)
    if(gridData.rowData.length !== 1) {
      setDefaultView(true)
    }
   
  }

}
useEffect(()=>{
  if(gridSelection.isSingleSku) {
  const payload = {
    "memberNbr": storeId,	
    "skuNbr": gridSelection.skuValue,
    "upc": gridSelection.upc
   
 }
  restUtils.postData(getSkuPricingDetail, payload).then(res=>{
    const data = res.data;
    setDefaultView(false)
    setPriceDetails(draft=>{
      draft.minPrice=data.minPrice;
      draft.skuNbr = data.skuNbr;
      draft.maxPrice=data.maxPrice;
      draft.newPrice= data.newPrice;
      draft.regularPrice =data.regularPrice;
      draft.marginAnalysisAttributes = data.marginAnalysisAttributes;
      draft.hasLineItems = data.hasLineItems
    })
    const price = data.newPrice === null ?  0 : data.newPrice
    const processMarginVal = marginAnalysis(
      data.newPrice,
      data.regularPrice,
      data.marginAnalysisAttributes.avgSales,
      data.marginAnalysisAttributes?.avgCost, 
      data.marginAnalysisAttributes.defaultPriceElasticity,
      data.marginAnalysisAttributes.nationalElasticity,
      data.marginAnalysisAttributes.priceElasticity,
      data.marginAnalysisAttributes.avgCost)
      setMarginAnalysisVal(processMarginVal)
      setNewPrice(price)
      setLabelVal(price)
      if(!_.isEmpty(modifyPrice)) {
       if(modifyPrice[data.skuNbr]) {
        setNewPrice(modifyPrice[data.skuNbr])
        setLabelVal(modifyPrice[data.skuNbr])
       }
    }
  }).catch(err=>{
    console.log(err)
  })
  
}
},[gridSelection])
useEffect(()=>{
  if(newPrice > 1) {
    const processMarginVal = marginAnalysis(
      Number.parseFloat(newPrice),
      priceDetails.regularPrice,
      priceDetails.marginAnalysisAttributes.avgSales,
      priceDetails.marginAnalysisAttributes?.avgCost, 
      priceDetails.marginAnalysisAttributes.defaultPriceElasticity,
      priceDetails.marginAnalysisAttributes.nationalElasticity,
      priceDetails.marginAnalysisAttributes.priceElasticity,
      priceDetails.marginAnalysisAttributes.avgCost)
      setMarginAnalysisVal(processMarginVal)
  }
},[newPrice])
useEffect(()=>{
  setGridSelection(draft=>{
    draft.isSingleSku= false;
    draft.skuImage = null
    draft.isNonTvSku = false;
  })
  setEnablePrice(false)
  setPagination(draft=>{
    draft.currentPage = 1
  })
 },[skuType])
 useEffect(()=>{
  setDefaultView(true)
  setGridSelection(draft=>{
    draft.isSingleSku= false;
    draft.skuImage = null
    draft.isNonTvSku = false;
  })
 },[filterType, skuType, pagination])
useEffect(()=>{
  setPagination(draft=>{
    draft.currentPage = 1
  })
  resetSingleSelection()
},[filterType])
const handleSlider =(e)=>{
  setNewPrice(e.target.value)
  setLabelVal(e.target.value)
}
const handleLabelVal =(e)=>{
   setLabelVal(e.target.value)
   
}
const onHandleBlur = (e)=>{
  let value = e.target.value
  if(value < priceDetails.minPrice || value > priceDetails.maxPrice) {
    setLabelVal(newPrice)
  }
  else {
    setNewPrice(Number.parseFloat(value).toFixed(2))
  }
}
const handleFocus = (e) =>{
  let value = e.target.value
    if (e.key === "Enter") {
      if(value < priceDetails.minPrice || value > priceDetails.maxPrice) {
        setLabelVal(newPrice)
        utilities.showToast('Entered price is not matching to Min and Max price')
      }
      else {
        setNewPrice(Number.parseFloat(value).toFixed(2))
        
      }
      e.preventDefault();
    }

}
const handleSubmit =()=>{
  const payload = {
    "memberNbr": storeId,	
    "skuNbr": priceDetails.skuNbr,
    "newPrice": newPrice   
 }
 restUtils.putData(updateNewPrice, payload).then(res=>{
   setModifyPrice(draft=>{
      draft[priceDetails.skuNbr] = newPrice
   })
   utilities.showToast('Price Override submitted successfully')
 }).catch(err=>{
  utilities.showToast('An error encountred while submitting price override', true)
 })
}
const [showPrompt, setShowPrompt] = useState(false)
const [ allowPage, setAllowPage] = useState(false)
const handlePriceOverride = () =>{
  setShowPrompt(false)
  setAllowPage(true)
}
useEffect(()=>{
  if(allowPage) {
    history.push({
      pathname: `/finalizePriceOverride`,
      state: { showPriceOverride: true}
    });
  }
},[allowPage])
useEffect(()=>{
   if(!_.isEmpty(modifyPrice)) {
    setShowPrompt(true)
   }
},[modifyPrice])
  return(
    <>
    {programCode !== 'ROS' && (
      <>
      <Prompt
        when={showPrompt}
        message="You have pending price overrides to finalize. It will be lost if not finalized"
      />
      {(!parseInt(skuType) || gridSelection.isNonTvSku) && <p className='nonTvMsg'>Price override is not possible for Non-TV SKUs</p>}
      <section className='PriceSliderWrapper'>
        <div>
        <div className='rangeSlider'>
             {defaultView || (priceDetails?.marginAnalysisAttributes &&  (priceDetails.marginAnalysisAttributes?.avgCost > priceDetails.marginAnalysisAttributes?.regularPrice)) ? (
              <>
              <div className='slidecontainer defaultSliderContainer'>
              <p>New Price</p>
              <input disabled = {true} className='priceLable' value={''}/>
             <input   disabled = {true} type="range" step="0.01" value={0} min= {0} max={0}  className="slider" id="myRange"/>
             </div>
               <button disabled style={{opacity: '0.3'}}>Submit</button>
               </>
             ):(
             <div className='slidecontainer'>
              <p>New Price</p>
              <input  className='priceLable' onChange={handleLabelVal} onBlur={onHandleBlur} value={labelVal !== 0 ? labelVal : ''} onKeyDown={handleFocus}/>
             <input   type="range" step="0.01" value={newPrice !== 0 ? newPrice : ''} min= {priceDetails.minPrice} max={priceDetails.maxPrice}  className="slider" id="myRange" onChange={handleSlider}/>
             </div>)}
             {(programCode ==="ROS" ||  (storeDetails.mainStore === false && storeDetails.uniformPrice)  || !enablePrice || priceDetails.marginAnalysisAttributes?.avgCost > priceDetails.marginAnalysisAttributes?.regularPrice) || !parseInt(skuType) ? '' : <button disabled={newPrice > 0 ? false : true} onClick={handleSubmit}>Submit</button>}
             
        </div>
        <div className={defaultView || (priceDetails?.marginAnalysisAttributes &&  (priceDetails.marginAnalysisAttributes?.avgCost > priceDetails.marginAnalysisAttributes?.regularPrice))? 'priceTable grayOutTable': 'priceTable' } >
          <table>
            <thead>
              <tr>
                <th>Current <br/>Price ($)</th>
                <th>New <br/>Price ($)</th>
                <th>Price <br/>Change (%)</th>
                <th>Break-Even <br/>Units (%)</th>
                <th>Sales <br/>Change (%)</th>
                <th>Exp. An. <br/>Margin improv ($)</th>
              </tr>
            </thead>
            <tbody>
              {defaultView || (priceDetails?.marginAnalysisAttributes &&  (priceDetails.marginAnalysisAttributes?.avgCost > priceDetails.marginAnalysisAttributes?.regularPrice)) ? (
               <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
               </tr>
              ):
              <tr>
                <td>{utilities.hyphenForNull(priceDetails.regularPrice)}</td>
                <td>{utilities.hyphenForNull(newPrice)}</td>
                <td>{marginAnalysisVal[0]  && newPrice > 0 ? `${marginAnalysisVal[0]}%` : '-' }</td>
                <td>{!_.isEmpty(marginAnalysisVal[1]) && marginAnalysisVal[1] !== "NaN" && newPrice > 0  ? `${marginAnalysisVal[1]}%` : '-' }</td>
                <td>{!_.isEmpty(marginAnalysisVal[2]) && marginAnalysisVal[2] !== "NaN"  && newPrice > 0 ? `${marginAnalysisVal[2]}%` : '-' }</td>
                <td>{!_.isEmpty(marginAnalysisVal[3]) && marginAnalysisVal[3] !== "NaN"  && newPrice > 0 ? `${utilities.commify(marginAnalysisVal[3])}` : '-' }</td>
              </tr>}
            </tbody>
          </table>
        </div>
        <div className='priceMsgBlock'>
          {gridSelection.isSingleSku &&  priceDetails.marginAnalysisAttributes &&  (priceDetails.marginAnalysisAttributes?.avgCost > priceDetails.marginAnalysisAttributes.regularPrice) && (
            <p class='redError'>Member Cost is more than the Price. Contact Retail Pricing to fix this issue</p>
          )}
          {gridSelection.isSingleSku && priceDetails.hasLineItems &&<p className='blackMsg'>There are line items corresponding to this SKU and the same price override is applied to all of them</p>}
        </div>
        </div>
       
        
      </section>
    <section className='gridTableSection'>
    {filterType.name === 'None' && skuType === '-1' ?
            <div className="gridTableWrapper">
            <div className="countPerPage">
              <div className="itemCountDiv">
                <div><p>Item Count</p>
                      <h3>0</h3>
                    </div>
              </div>
              </div>
      <div className="gridTable">      
      <table>
         <thead>
            <tr>
              {header['TabFive'].map((cell,index)=>{          
               return(
                <th key={cell.key} >               
                    {cell.name}
                </th>
               )
              })}
            </tr>
         </thead>
         </table>
         <div className='gridNoDataBox'>
              <h3>No Data to Display</h3>
              <p>Please make a selection</p>
         </div>
         </div> </div> :
         <>
    {gridData.isLoading && <Loader active/>}
    {gridData.rowData && (
      gridData.rowData.length > 0 ? (
        <GridTable 
        id={'TabFive'}
        data = {gridData}
        pagination = {pagination}
        sortCol = {sortCol}
        onPageChange={onPageChange}
        handleCount={handleCount}
        handleSort ={handleSort}
        handleSelection = {handleRowSelection}
        image={gridSelection.skuImage}
        isSkuSelection = {gridSelection.isSingleSku}
        defaultSort= {17}
         />
        
      ): <p className='noDataMsg'>Insufficient data</p>)}  
          {
          (programCode ==="ROS" ||  (storeDetails.mainStore === false && storeDetails.uniformPrice)) || !parseInt(skuType) || gridSelection.isNonTvSku ? '' :
          <div className="whatIfSubmit">
            <button onClick={handlePriceOverride}>Finalize Price Override</button>
          </div>
          }
          </>
      }
    </section>
    </>
    )}
    </>
  )
}

export default  WhatIfCalculator;
