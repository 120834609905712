import React, { memo, useEffect, useState  } from "react";
import './style.scss'
import { header } from './gridHeader'
import { useSelector } from 'react-redux';
import { getPagination } from "../../views/common/CustomComponents";
import ExportExcel from '../../views/common/ExportExcel';
import utilities from "../../utils/utilities";
import {PRICE_CHANGE_SUMMARY,PRODUCT_MASTER_LIST} from '../../views/common/constant'
const GridTable = (props)=>{
   const { programCode, storeDetails } = useSelector(state => state.SessionReducer)
const { rowData,  totalData, itemCount} = props.data
const { sortCol, pagination} = props
const pathname = window.location.pathname
let rowRef = {}
const [ removeItem, setRemoveItem] = useState([])
const handleRowSelect = (cell, index)=>{
   if(props.id ==='priceOverride'|| props.id === 'productMasterList') {
      return
   }
   const path  = document.querySelectorAll('.gridRow')
   if(rowRef[index].current.classList.contains('activeRow')) {
      rowRef[index].current.classList.remove('activeRow')
      path.forEach((item)=>{
         item.style.opacity = '1'
       })
      props.handleSelection([])
   }
   else {
      path.forEach((item)=>{
         item.classList.remove('activeRow')
         item.style.opacity = '0.3'
       })
      rowRef[index].current.classList.add('activeRow')
      rowRef[index].current.style.opacity = '1'
      props.handleSelection(cell)
   }
}
useEffect(()=>{
   setRemoveItem([])
},[props.data])
useEffect(()=>{
   if(!props.isSkuSelection) {
      const path  = document.querySelectorAll('.gridRow')
      path.forEach((item)=>{
         item.style.opacity = '1'
         item.classList.remove('activeRow')
       })
   }
   
   },[props.isSkuSelection])
   const renderTotal = () =>{
      if(props.id==='TabOne') {
         return (
            <>
               <td colSpan={6}>Total</td>
               <td>{utilities.commify(totalData.ytdUnits)}</td>
               <td>{utilities.commify(totalData.ytdUnitsLY)}</td>
               <td>{totalData.ytdUnitsChangePct}%</td>
               <td>{utilities.commify(totalData.ytdMargin)}</td>
               <td>{utilities.commify(totalData.ytdMarginLY)}</td>
               <td>{utilities.commify(totalData.ytdMarginChangePct)}%</td>
               <td>{utilities.commify(totalData.ltmUnits1)}</td>
               <td>{utilities.commify(totalData.ltmUnits2)}</td>
               <td>{totalData.ltmUnitsChangePct}%</td>
               <td>{utilities.commify(totalData.ltmMargin1)}</td>
               <td>{utilities.commify(totalData.ltmMargin2 )}</td>
               <td>{totalData.ltmMarginChangePct}%</td></>
         )
      }
      else if(props.id ==='TabFive' || props.id==='competitorPrices' || props.id==='suggPrice') {
         return (
            <>
             <td colSpan={16}>Total</td>
             <td style={{background:'#b0d0d3'}}>{utilities.commify(totalData.marginChange)}</td>
            </>
         )
      }
      else if(props.id === 'storePricingMix' ||props.id ==='priceView' ) {
         return (
            <>
             <td colSpan={7}>Total</td>
             <td style={{background:'#e9c46a'}}>{utilities.commify(totalData.marginChange)}</td>
             <td></td>
            </>
         )
      }
   }
   const handleDeleteRow =(event) =>{
      const isChecked = event.target.checked
      const value = event.target.value
      if(isChecked) {
         if(!removeItem.includes(value)) {
            setRemoveItem(val => [...val, parseInt(value)])
         }
      }else {
         const filterItem = removeItem.filter(ele => ele !== parseInt(value))
         setRemoveItem(filterItem)
      }
   }
   const handleFbRangeClick = (cell) =>{
      props.handlePopup(cell.skuNbr)
   }
 return (
    <>
    <div className="gridTableWrapper" id={props.id}>
    <div className="countPerPage">
      <div className="itemCountDiv">
         <div><p>Item Count</p>
              <h3>{props.isSkuSelection ? '1' :utilities.formatNumber(itemCount, true)}</h3>
            </div>
             {window.location.pathname !== `/${PRODUCT_MASTER_LIST}` &&
              (props.image || (props.data.rowData.length === 1 && props.data.rowData[0].isTvSku)) &&(
               <img src={`https://images.truevalue.com/GetImage.asp?id=${props.image ? props.image: props.data.rowData[0].skuNbr}&imageType=1&noImageType=1&colorImageSize=3`} alt='skuImage'/>
              )}
      </div>
      <div>
      
      {window.location.pathname === `/${PRICE_CHANGE_SUMMARY}` ? rowData.length ? <ExportExcel 
      id={props.id} filterType={props.filterType} totalCount = {itemCount } skuType = {props.skuType} distType ={props.distType}  sortColumn= {props.sortColumn}
      sortOrder=  {props.sortOrder}  priceChangeType={props?.priceChangeType}/>: <button className = 'downloadBtn' disabled>Download</button>: ''}
      {props.id === 'priceOverride' && removeItem.length > 0 && <button className = 'removeItemBtn' onClick={()=>{props.handleDeleteRow(removeItem)}}>DELETE</button> }    
      <span>Show </span>
      <select value={sortCol.perPage} onChange={props.handleCount}>
         <option value={20}>20</option>
         <option value={50}>50</option>
      </select>      
      
      </div>
      </div>
    </div>
    <div className="gridTable">      
      <table style={{width:"100%"}}>
         <thead>
            <tr>
              {header[props.id].map((cell,index)=>{         
               return(
                  index === props.defaultSort-1 ? (                     
                     <th className={cell.key ==='shortDesc'? "descrptionRow":''} onClick={()=>{props.handleSort(cell,index+1);props.handleSelection([], true)}} key={cell.key} data-state={cell.name}>               
                     {cell.name} {index === sortCol.colIndex-1 && <i className={ !sortCol.key === '' || sortCol[cell.key] ? 'caret down icon': 'caret up icon'} />}
                  </th>
                  ):<th className={cell.key ==='shortDesc'? "descrptionRow":''}  onClick={()=>{props.handleSort(cell,index+1);props.handleSelection([], true)}} key={cell.key} data-state={cell.name}>               
                  {cell.name} {cell.key !== "checkbox" && index === sortCol.colIndex-1 && <i className={ sortCol.colIndex === sortCol.key === '' || !sortCol[cell.key] ? 'caret down icon': 'caret up icon'} />}
                  </th>
               )
              })}
              {props.id === 'priceOverride' && <th> Remove</th>}
            </tr>
         </thead>
         <tbody>
               {rowData.map((cell,index)=>{
                  rowRef[index] = React.createRef()
                  return(
                     <tr ref ={rowRef[index]} className='gridRow'  id={`row_${index}`} onClick={()=>{handleRowSelect(cell,index)}} key={ pathname=== `/finalizePriceOverride` ? cell.skuNbr : cell.id}>
                        {header[props.id].map(val=>{
                           if(val.key === 'checkbox') {
                              if(props.id === 'productMasterList' ) {
                                 return <td  key={cell[val.name]}><input type='checkbox' checked = {cell.checkBox} value={cell.skuNbr} onChange={props.handleDeleteRow}/></td>
                              }
                              return <tr key={cell.name}> <input type='checkbox'/></tr>
                           }
                           if(props.id === 'productMasterList' ){
                              return  <td key={cell[val.name]}>{utilities.hyphenForNull(cell[val.key])}</td>
                           } else if( props.id==='competitorPrices' && val.key === 'fairPrice') {
                              return  <td onClick={()=>{handleFbRangeClick(cell)}} key={cell[val.name]}>{utilities.hyphenForNull(cell[val.key])}</td>
                           }
                            return (
                              val.name !== 'SKU #' && parseInt(cell[val.key]) && val.key !== 'fpRange' ? <td key={cell[val.name]}>{(val.key ==="marginChange" || val.key === 'avgCost' || val.key ==='replCost') ? utilities.hyphenForNull(utilities.commify(cell[val.key].toFixed(2))) : utilities.hyphenForNull(utilities.commify(cell[val.key]))}</td>:
                              <td key={cell[val.name]}>{utilities.hyphenForNull(cell[val.key])}</td> )
                        })}
                         {props.id === 'priceOverride' && <td><input type='checkbox' checked = {removeItem.includes(cell.skuNbr)} value={cell.skuNbr} onChange={handleDeleteRow}/></td>}
                     </tr>
                     
                  )
               })}
         </tbody>
         <tfoot>
            <tr key='totalRow'>
               {renderTotal()}               
            </tr>
         </tfoot>
      </table>
    </div>
      {pathname=== `/finalizePriceOverride` &&(
         <>
          <div className="overrideConfirmation">
            {(programCode ==="ROS" ||  storeDetails.multiStoreGroup === false  || (storeDetails.multiStoreGroup === true && storeDetails.mainStore === false)) ? '' :
            <div>
               <span>Apply price overrides to other stores in the group</span>
               <input type='radio' id='yes' value={1} onChange ={props.handleSelectGroup} name ='priceOveride'/>
                <label htmlFor='yes'>Yes</label>
               <input type='radio' id='No' value={0} onChange ={props.handleSelectGroup} name ='priceOveride'/>
               <label htmlFor='No'>No</label>
            </div>}
            <button onClick={props.handleFinalize}>Finalize</button>
            
          </div>
         </>
      )}
    <div className="paginationWrapper">
    {  getPagination(0, pagination.currentPage, pagination.totalPages, props.onPageChange, null, 1)}
    </div>   
    </>
 )
}

export default memo(GridTable)